<ion-header>

  <ion-toolbar>
    <ion-buttons>
      <button navPop clear>
        <ion-icon (click)="dismiss()" name="close" style="font-size: 30px"></ion-icon>
      </button>
    </ion-buttons>
    <ion-title></ion-title>

  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card  >
    <img *ngIf="spot.w400" src="https://russiatravel.club/images/spots/{{spot.w400}}" />
    <ion-card-header>
      <ion-card-subtitle>{{spot.regionString}}</ion-card-subtitle>
      <ion-card-title>{{spot.title}}</ion-card-title>
    </ion-card-header>
  </ion-card>
  <ion-card *ngIf="spot.newsBody">
    <ion-card-header>
      {{spot.newsTitle}}
    </ion-card-header>
    <ion-card-content>
      {{spot.newsBody}}
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-content>
      {{spot.body}}
    </ion-card-content>
    <ion-card-content>
      <div *ngIf="spot.url" class="spots-url-external">{{spot.url}}</div>
      <div class="spots-organisation">{{spot.organisationName}}</div>
    </ion-card-content>
  </ion-card>
</ion-content>
