import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
// import { PopoverController } from '@ionic/angular';
import { DataService } from './data-service.service';


@Injectable({
  providedIn: 'root'
})
export class InterfaceService {

  constructor(
    public alertController: AlertController,
    // public popoverController: PopoverController,
    private dataService: DataService,
  ) { }


  public async alertIfNotConnected() {
    
    var connected = await this.dataService.ifUserConnected();
    if(!connected) {
      const alert = await this.alertController.create({
        header: 'Обратите внимание',
        subHeader: 'Вы не авторизованы!',
        message: 'Перейдите в Настройки и авторизуйтесь чтобы иметь доступ к комментированию, лайкам. Присоединяйтесь к RussiaTravel.club !',
        buttons: ['OK']
      });
      await alert.present();
    }
    return connected;
  }
  async getAlert(title, messageOnScreen){
    const alert = await this.alertController.create({
      header: title,
      // subHeader: 'Вы не авторизованы!',
      message: messageOnScreen,
      buttons: ['OK']
    });
    await alert.present();
  }

  processRegCode(newRegCode){
    var params = [];
    params['regCode'] = newRegCode;
    // debugger;
    return this.dataService.postActionData("regCode", params).then((p: any) => {
      if(p.status && p.status=='ok'){
        var msg = "Вам зачислены " + p.points + ' баллов';
        this.getAlert('Спасибо!', msg);
        
      }
      else {
        if(p.message){
          this.getAlert('Ошибка', p.message);
        }
        else
          this.getAlert('Ошибка', "Произошла ошибка при обработке кода( Пожалуйста, попробуйте ввести код в бот или напишите нам о проблеме");
      }
      
    });
  }

}
