import { Component } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './../app/data-service.service';
import { InterfaceService } from './interface-service.service';


import { Router } from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { DataUpdaterService } from './../app/data-updater.service';
import { SwUpdate } from '@angular/service-worker';
import { UserdataService } from './services/userdata.service';
import { Userdata } from './classes/userdata';

//  import firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appPages: any = [];
  public selectedIndex = 0;
  // public userCurrentRegion: string = "Не выбран";
  public userData: Userdata = new Userdata();


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private dataService: DataService,
    private afMessaging: AngularFireMessaging,
    private dataUpdater: DataUpdaterService,
    private swUpdate: SwUpdate,
    private alertController: AlertController,
    private interfaceService: InterfaceService,
    public userdataService: UserdataService

  ) {

    this.initializeApp();
    this.iniMenu();
    userdataService.updateUserData().then((userInfo: Userdata) => {
      console.log("userInfo: ");
      console.log(userInfo);
      this.userData = userInfo;
      // if (userInfo && userInfo.currentRegionString)
      //   this.userCurrentRegion = userInfo.currentRegionString;
    });

    // var userInfo = userdataService.getUserData();


  }


  iniMenu() {
    this.appPages = [
      {
        title: 'Главное',
        url: '/tabs/feed',
        iconFull: 'bulb'
      },
      {
        title: 'Регионы',
        url: '/regions',
        icon: 'location'
      },
      {
        title: 'Вопросы/ответы',
        url: '/tabs/settings',
        icon: 'help-circle'
      },
      {
        title: 'Темы',
        url: '/tabs/settings',
        icon: 'pricetags'
      },
      {
        title: 'Новости',
        url: '/tabs/settings',
        icon: 'newspaper'
      },
      {
        title: 'ТИЦы',
        url: '/tabs/settings',
        icon: 'information-circle'
      },
      {
        title: 'Музеи',
        url: '/tabs/settings',
        icon: 'keypad'
      },
      {
        title: 'События',
        url: '/tabs/settings',
        icon: 'calendar'
      },            /*{
          title: 'Dashboard',
          url: '',
          icon: 'easel'
      },
      {
          title: 'Timeline',
          url: '/timeline',
          icon: 'film'
      },
      */
      {
        title: 'Бонусы',
        url: '/tabs/bonuses',
        icon: 'gift'
      },
      {
        title: 'Настройки',
        url: '/tabs/settings',
        icon: 'settings'
      },

    ];
  }

  gotoSettings() {
    this.router.navigate(['/tabs/settings'], { skipLocationChange: false });
  }

  checkForAccessCodeInUrl() {
    if (window.location.pathname) {
      var prefix = window.location.pathname.match(/access-to-([\d\w]*)ru/);
      if (prefix && prefix.length == 2) {
        return prefix[1];
        //   this.router.navigate(['/login', {accessCode: prefix[1]}]);
      }
      else
        return null;
    }
  }


  iniFirebaseMessaging() {
    console.log("subscribe on component...");
    this.afMessaging.onMessage((payload) => {
      console.log('Message received 11. ', payload);
      // ...

    });
    this.afMessaging.onTokenRefresh((x) => {
      console.log("token refresh");
    });
  }

  debbugInfo(msg) {
    console.log(msg);
  }

  initializeApp() {

    setInterval(() => {
      this.swUpdate.checkForUpdate();
    }, 21600);
    this.checkUpdate();

    this.iniFirebaseMessaging();

    var currentHashCode = "";
    // debugger;
    this.platform.ready()
      .then(() => {
        this.debbugInfo("Platform ready");
        return this.dataService.settings.get("hashCode");
      })
      .then((hashCode) => {
        this.debbugInfo("Hash code: " + hashCode);
        currentHashCode = hashCode;

        // логика проверки.
        // запрашиваем с сервера данные о том, какая версия актуальная
        const formData = new URLSearchParams();
        formData.append('version', 'get');
        this.debbugInfo("call assess");
        return this.dataService.postData("https://russiatravel.club/pwa/access.php", formData);
      })
      .then((res) => {

        var t = res.json();

        return t;
      })
      .then((t) => {
        this.debbugInfo("response t: ");
        this.debbugInfo(t);
        // сравниваем с версией, которая прошита в коде
        // если версия в коде меньше версии, которую получили с сервера, перезагружаем страницу
        var currentVersion = this.dataService.getCurrentVersion();
        this.debbugInfo("currentVersion " + currentVersion);
        if (t && t.version && t.version > currentVersion) {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (let registration of registrations) {
                registration.update()
              }
            })
          }
          // window.location.reload(true);
        }
        else {
          return true;
        }
      })
      .then(() => {
        // проверка наличия URL в запросе. Если URl есть, считается, что пользователь не авторизован - необходимо сбросить ему авторизацию и выполнить новую если параметр авторизации не совпадает.
        var accessCodeInUrl = this.checkForAccessCodeInUrl();
        if (accessCodeInUrl) { }
        else
          accessCodeInUrl = '';
        return Promise.all([accessCodeInUrl, this.dataService.settings.get('urlAccessCode')]);

      })
      .then((x) => {
        var accessCodeInUrl = x[0];
        var dbUrlCode = x[1];
        // пользователь считается авторизованным если в базе есть запись urlAccessCode и она совпадает с текущим url, либо текущего url нет
        // подолнительно нужна проверка того, что hash присутствует в локальной базе

        if ((currentHashCode != '') && (dbUrlCode != '') && ((accessCodeInUrl == dbUrlCode) || (accessCodeInUrl == ''))) {
          return [true, ''];
        }
        else
          return [false, accessCodeInUrl];

      })
      .then(autorisated => {
        var makeUpdate: any = true;
        if (autorisated[0] && (currentHashCode != '')) {
          makeUpdate = this.dataUpdater.checkAndMakeUpdate();
        }
        return Promise.all([makeUpdate, autorisated[0], autorisated[1]]);
      })
      .then((x) => {

        if ((x[1]) && (currentHashCode != ''))
          this.router.navigate(['/']);
        else {
          var accessCodeInUrl = x[2];
          if (accessCodeInUrl
            // || (currentHashCode == '')
          )
            this.router.navigate(['/login', { accessCode: accessCodeInUrl }]);
          else
            this.router.navigate(['/']);
        }


        this.statusBar.styleDefault();
        this.splashScreen.hide();
      });
  }
  checkUpdate() {
    console.log("checkUpdate");
    if (this.swUpdate.isEnabled) {
      console.log("checkUpdate: enable");
      this.swUpdate.available.subscribe(async () => {
        console.log("checkUpdate: exist ask client");
        const alert = await this.alertController.create({
          header: `Приложение обновлено!`,
          message: `Доступна свежая версия приложения. Обновление не удалит данные. Обновить?`,
          buttons: [
            {
              text: 'Не обновлять',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Обновить',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
        await alert.present();
      });
    }
  }





}
