export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB2hAbvTW6DwBEfLQ5WBvBqqUIpCN1qSNs",
    authDomain: "russia-travel-club.firebaseapp.com",
    projectId: "russia-travel-club",
    storageBucket: "russia-travel-club.appspot.com",
    messagingSenderId: "853199150836",
    appId: "1:853199150836:web:c142cf2f9fc4d5635708a1"
  }
};
