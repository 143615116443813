import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    
    //  redirectTo: '/regions', pathMatch: 'full'
    // redirectTo: '/regions/426562904777530', pathMatch: 'full'
    // redirectTo: '/regions/486401320888103', pathMatch: 'full'
    // redirectTo: '/regions/486401320888103/reviews', pathMatch: 'full'
    // https://localhost:8100/regions/188935094688959/reviews
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  
   {
     path: 'login',
     loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
   },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  
  {
    path: 'bonuses',
    loadChildren: () => import('./bonuses/bonuses.module').then( m => m.BonusesPageModule)
  },
  {
    path: 'qr-scanner',
    loadChildren: () => import('./qr-scanner/qr-scanner.module').then( m => m.QrScannerPageModule)
  },
  {
    path: 'sketches',
    loadChildren: () => import('./sketches/sketches.module').then( m => m.SketchesPageModule)
  },
  {
    path: 'feed',
    loadChildren: () => import('./feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'prize-list',
    loadChildren: () => import('./prize-list/prize-list.module').then( m => m.PrizeListPageModule)
  },
  {
    path: 'checkinplaces-list',
    loadChildren: () => import('./checkinplaces-list/checkinplaces-list.module').then( m => m.CheckinplacesListPageModule)
  },
  
  {
    path: 'regions',
    // pathMatch: 'full',
    loadChildren: () => import('./regions/regions.module').then( m => m.RegionsPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'media-preview',
    loadChildren: () => import('./pages-services/media-preview/media-preview.module').then( m => m.MediaPreviewPageModule)
  },
  {
    path: 'reviews-list',
    loadChildren: () => import('./pages/lists/reviews-list/reviews-list.module').then( m => m.ReviewsListPageModule)
  },
  // {
  //   path: 'region',
  //   loadChildren: () => import('./region/region.module').then( m => m.RegionPageModule)
  // },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
