import { Injectable } from '@angular/core';
import { DataService } from './../app/data-service.service';
import { Storage } from '@ionic/storage';
import {UserdataService } from './services/userdata.service';


@Injectable({
  providedIn: 'root'
})
export class DataUpdaterService {

  constructor(
    private storage: Storage,
    private dataService: DataService,
    public userdataService : UserdataService

  ) { }


  async checkAndMakeUpdate() {
    // запрашивает данные из базы данных, сеттинга, получая последние updates
    // запрашивает данные с сервера
    // если получены данные, то обновляет их в базе

    var lastChange = this.dataService.settings.get("lastChanges");


    return lastChange.then(h=>{
      const formData = new URLSearchParams();
      formData.append('dataType', 'checkDataUpdates');
      if(h && h.lastData)
        formData.append('lastDt', h.lastData);

      return this.dataService.postData("https://russiatravel.club/pwa/pwaData.php", formData);  
    })
    .then(x=>{
      return x.json();
    })
    .then(x=> {
      console.log(x);
      
      var updaterSpot = this.updateSpots(x.spots, x.likes.filter(x=> x['objectType'] == "spot"));

      var updaterTexts = this.updateTexts(x.texts);
      var updateTics = this.updateTics(x.tics);
      var userInfo = this.userdataService.updateUserData();
      
      return Promise.all([x, updaterSpot, updaterTexts, updateTics, userInfo]);
    })
    .then((x:any)=>{
      if(x[1] === true && x[2] === true &&  x[3] === true && x[0].stats && (x[0].stats.lastData || x[0].stats.lastLike )){
        var lastData = "";

        if((x[0].stats.lastData ??'') > (x[0].stats.lastLike ?? ''))
          lastData = x[0].stats.lastData ?? '';
        else
          lastData = x[0].stats.lastLike ?? '';
        this.dataService.settings.set("lastChanges", {lastData : lastData});
      }
    });
  }

  updateTics(remote) {
    if(remote && remote.tics && remote.tics.length > 0){
      return this.storage.get("tics").then((local: any) => {
        if(local) {}
        else{
          local = {
            data : []
          };
        }
        remote.tics.reverse().forEach(element => {
          var index = -1;
          if(local.data)
            index = local.data.findIndex((x) => x.id == element.id);
          if(index != -1){
            if(element.isDeleted == 1){
              local.data.splice(index,1);
            }
            else {
              local.data[index] = element;
            }
          }
          else {
            if((typeof(local) == 'undefined') || typeof(local.data) == 'undefined'){
              local = {};
              local.data = [];
            }
            local.data.unshift(element);
          }
        });
        if(remote['lastData'])
          local['lastData'] = remote['lastData'];
          //TODO поправить Likes
        // if(local['lastLike'] = (remote['lastLike']) ? remote['lastLike'] : "";
        return local;
      })
      .then((local)=>{
        return this.storage.set("tics", local);
      })
      .then((x) => {
        return true;
      });
    }
    else 
      return true;
  }

  updateTexts(remote) {
    if(remote && remote.texts && remote.texts.length > 0){
      return this.storage.get("textInfo").then((local: any) => {
        if(local) {}
        else{
          local = {
            data : []
          };
        }
        remote.texts.reverse().forEach(element => {
          var index = -1;
          if(local.data)
            index = local.data.findIndex((x) => x.id == element.id);
          if(index != -1){
            if(element.isDeleted == 1){
              local.data.splice(index,1);
            }
            else {
              local.data[index] = element;
            }
          }
          else {
            if((typeof(local) == 'undefined') || typeof(local.data) == 'undefined'){
              local = {};
              local.data = [];
            }

            local.data.unshift(element);
          }
        });
        if(remote['lastData'])
          local['lastData'] = remote['lastData'];
          //TODO поправить Likes
        // if(local['lastLike'] = (remote['lastLike']) ? remote['lastLike'] : "";
        return local;
      })
      .then((local)=>{
        return this.storage.set("textInfo", local).then((x)=> { return local;});
      })
      .then((x) => {
        return true;
      });
    }
    else
      return true;

  }

  updateSpots(remote, likes){
    if(remote && remote.spots && (remote.spots.length > 0 || (likes && likes.length > 0))) {
      return this.storage.get("spots").then((local: any) => {
        if(local) {}
        else{
          local = {
            data : []
          };
        }
        remote.spots.reverse().forEach(element => {
          
          var index = -1;
          if(local.data) {
            if(typeof local.data == 'object')
              local.data = Object.entries(local.data).map((x)=> x[1]);
            index = local.data.findIndex((x) => x.spotCode == element.spotCode);
          }
          if(index != -1){
            if(element.isDeleted == 1){
              local.data.splice(index,1);
            }
            else {
              local.data[index] = element;
            }
          }
          else {
            if((typeof(local) == 'undefined') || typeof(local.data) == 'undefined'){
              local = {};
              local.data = [];
            }

            local.data.unshift(element);
          }
        });
        if(remote['lastData'])
          local['lastData'] = remote['lastData'];
          //TODO поправить Likes
        // if(local['lastLike'] = (remote['lastLike']) ? remote['lastLike'] : "";
        return local;
      })
      .then((local) => {
        
         if(likes && likes.length > 0) {
           likes.forEach(element => {
            var index = -1;
            if(local.data)
                index = local.data.findIndex((x) => x.spotCode == element.objectCode);
            if(index != -1){
              local.data[index].rating = element.rating;
            }
           });
         }
         return local;
      })
      .then((local)=>{
        return this.storage.set("spots", local).then((x)=> { return local;});
      })
      .then((x) => {
        return true;
      })
      /*
      .then((x=>{
        if(x['lastData']) {
          return this.dataService.settings.set("lastChanges", {
            lastData: x['lastData'],
            lastLike: x['lastLike']
          } );
        }
        else
          return true;
      }))
      .then(()=>{
        return true;
      })
      */
      ;
    }
    else
      return true;

  }


}
