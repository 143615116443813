import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
//import { DataService } from '../data-service.service';
import { DataService } from '../../data-service.service';


@Component({
  selector: 'app-spot-details-modal',
  templateUrl: './spot-details-modal.page.html',
  styleUrls: ['./spot-details-modal.page.scss'],
})
export class SpotDetailsModalPage implements OnInit {

  spotCode: any = null;
  spot: any = null;
  
  constructor(
    navParams: NavParams, 
    public viewCtrl: ModalController,
    private dataService: DataService,


  ) {
    this.spot = [];
    this.spotCode = navParams.get('spotCode');
    this.dataService.getSpot(this.spotCode).then((spot) => {
      this.spot = spot;
    });

  }

  ngOnInit() {
    
    

  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

}
