import { Injectable } from '@angular/core';
import { DataService } from '../data-service.service';
import { Userdata } from '../classes/userdata';


@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  userdata: Userdata;// = {};

  constructor(
    private dataService: DataService,

  ) {
    this.userdata = new Userdata();
    this.updateUserData();
    this.dataService.authorized.subscribe((value) => {
      console.log("setting: authorized.subscribe, new value:", value);
      this.userdata.userConnectedBool = value;
      this.updateUserData();
    });
  }

  updateUserData() {
    return new Promise((resolve, reject) => {
      this.dataService.ifUserConnected().then((x) => {
        console.log("setting: updateUserInfo, new value:", x);
        this.userdata.userConnectedBool = x;
        return x;
      }).then(async (x) => {
        
        if (x) {
          await this.dataService.postInfoData("userSettings").then((s: any) => {
            this.userdata.userName = s.customusername;
            this.userdata.userNameLabel = s.customusername;
            this.userdata.currentRegionFias = s.currentRegionGuid;

          });
        }
        else {
          this.userdata.userName = "";
          this.userdata.userNameLabel = "не указано";
          this.userdata.currentRegionFias = "";
          this.userdata.currentRegionString = "";
        }
      }).then(()=>{
        if(this.userdata.currentRegionFias){
          this.userdata.currentRegionString = this.dataService.regionNameByFias(this.userdata.currentRegionFias);
        }
        resolve(this.userdata);
      });
    });
  }

  getUserData() {
    return this.userdata;
  }
}
