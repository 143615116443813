import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage';
import { DatePipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
//  import { MainMenuModule} from './main-menu.module';


// import { SpotDetailsModalPageModule } from './spot-details-modal/spot-details-modal.module';
import { SpotDetailsModalPageModule } from './tab1/spot-details-modal/spot-details-modal.module'
// import { MainmenuComponent } from './components/mainmenu/mainmenu.component';

@NgModule({
  declarations: [AppComponent
    //  , MainMenuComponent
    //  , MainMenuModule
    // ,MainmenuComponent
  ],
  entryComponents: [
    // MainMenuComponent
  ],
  imports: [BrowserModule, IonicModule.forRoot(), HttpClientModule, AppRoutingModule,   
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    IonicStorageModule.forRoot(), 
    
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    SpotDetailsModalPageModule,
    
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    BarcodeScanner,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
