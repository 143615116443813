<ion-app>
  <ion-split-pane contentId="main-content" >

    <!-- *ngIf="!small" -->
    <ion-menu content="mymenu" auto content-id="main-content">
      <div class="left-menu">

        <img src="/assets/images/RussiaTravelclub-200x52-white.png" style="width: 200px;">

        <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item class="menu-item" (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]"
              lines="none" detail="false" [class.selected]="selectedIndex == i">
              <ion-icon class="menu-item-icon" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'" *ngIf="!p.iconFull"></ion-icon>
              <ion-icon class="menu-item-icon" slot="start" [ios]="p.iconFull" [md]="p.iconFull" *ngIf="p.iconFull"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>


        <div class="mobileui">
          

          <div class="padding border-grey-400 shadow radius left-divs " (click)="gotoSettings()">
            <h2>Ваш статус</h2>
            <div class="text-small">
              {{userData.userStatusString}}
            </div>
          </div>
          <div class="space"></div>
          <div class="padding border-grey-400 shadow radius left-divs green-50">
            <h2>Бонусная программа</h2>
            <div class="text-small">
              Участвуйте в бонусной программе, копите баллы за путешествия.<br>
              Баллы начисляются:<br>
              За посещения музеев<br>
              За посещения туристско-информационных центров<br>
            </div>
          </div>

          <div class="space"></div>

          <div class="padding border-grey-400 shadow radius left-divs " (click)="gotoSettings()">
            <h2>Выбранный регион</h2>
            <div class="text-small">
              {{userData.currentRegionString}}
            </div>
          </div>



        </div>
      </div>
    </ion-menu>
    <ion-router-outlet id="main-content" ></ion-router-outlet>
  </ion-split-pane>
</ion-app>