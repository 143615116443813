import { Injectable } from '@angular/core';
// import { DefaultUrlSerializer } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { waitForAsync } from '@angular/core/testing';
// import { stat } from 'fs';
// import { resolve } from 'dns';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  accessHashCode: string;
  initionlizated: boolean = false;
  version: number = 15;
  // authorized: boolean = false;
  authorized: Subject<boolean> = new Subject<boolean>();
  _authorized: boolean = false;

  mainDataServer = "https://russiatravel.club/pwa/appPwaData.php";
  // authorizedObserver: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private router: Router,
  ) {
    this.authorized.next(false);
    this.iniHashCode();
  }

  getCurrentVersion() {
    return this.version;
  }


  setAuthorized(value) {

    this._authorized = value;
    this.authorized.next(value);

  }


  async ifUserConnected() {

    var status = false;
    status = await new Promise((resolve, reject) => {

      this.settings.get("hashCode").then((x) => {

        if (x)
          resolve(true);
        else
          resolve(false);
      });
    });

    return status;
  }

  async iniHashCode() {
    var ret = await this.settings.get('hashCode').then(x => {
      this.initionlizated = true;

      if (x) {
        this.accessHashCode = x;
        this.setAuthorized(true);
        return x;

      }
      else {
        this.setAuthorized(false);
        return "";
      }

    });

    return ret;
  }

  // главная функция для работы с сервером
  async postData(url, formData) {

    if (!this.initionlizated)
      await this.iniHashCode();

    var options: RequestInit = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      // credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData.toString() // body data type must match "Content-Type" header
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    };
    /*
        if (this.accessHashCode) {
          options.headers = {
            // 'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-auth': this.accessHashCode
          }
        }
        else {
          options.headers = {
            // 'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }
        */
    options.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-auth': (this.accessHashCode ? this.accessHashCode : 'empty')
    }
    console.log("data.service: send fetch to " + url);
    const response = await fetch(url, options);

    return response; // parses JSON response into native JavaScript objects
  }

  postDataOrGoToLogin(url, formData) {
    // debugger;
    return new Promise((resolve) => {
      const response = this.postData(url, formData);

      var result = response.then((res) => {
        var t = res.json();
        return t;

      }).then((t) => {
        if (t.error && t.error == 'login') {
          this.router.navigate(['/login', { accessCode: '' }]);


          return t;
        }
        if (t.status && (t.status.authorized == 'authorized')) { }
        else {
          this.setAuthorized(false);
          this.settings.set("hashCode", "");
        }

        console.log(t);
        resolve(t);
      }
      ).catch((p) => {
        // debugger;
        console.log("Error in request " + url);
      });
    });
  }


  async logout() {
    // debugger;
    
    this.settings.clearAll().then((x => {
      this.setAuthorized(false);
       this.router.navigate(['/']).then(() => {
        //  window.location.reload();
       });
    }));
  }

  userAutorisation(urlAccessCode, confirmationCode) {

    return new Promise((resolve, reject) => {
      this.settings.get("browerUserCode").then((browerUserCode: any) => {
        if (browerUserCode)
          return ({
            type: "db",
            browerUserCode: browerUserCode
          });
        else {
          browerUserCode = this.makeId(30);
          return ({
            type: "new",
            browerUserCode: browerUserCode
          });
        }
      }).then((browerUserCodeObject: any) => {
        const formData = new URLSearchParams();
        const browerUserCode = browerUserCodeObject.browerUserCode;

        formData.append('confirmationCode', confirmationCode);
        formData.append('urlAccessCode', urlAccessCode);
        formData.append('browerUserCode', browerUserCode);

        const response = this.postData(this.mainDataServer, formData);
        var result = response.then((res) => {
          // тут должно быть что то что позволяет "сбросить соединение" если пользователь авторизован на другом браузере - и выдавать предупреждение
          var t = res.json();
          return t;
        }).then((t) => {
          if (t && t.status == 'ok') {
            this.accessHashCode = t.hashCode;
            this.settings.set('urlAccessCode', urlAccessCode).then(() => {
              this.settings.set('hashCode', t.hashCode).then((p) => {
                this.settings.set('browerUserCode', t.browerUserCode).then((k) => {
                  this.iniHashCode();
                  if (t.browerUserCodeStatus != 'different')
                    resolve({ status: 'ok' });
                  else
                    resolve({ status: 'ok', browerUserCodeStatus: 'different' });
                });
              });
            });

          }
          else {
            resolve({ status: 'error' });
          }
        }).catch(() => {
          resolve({ status: 'error' });
        });
      });
    });
  }
  clearUserData() {
    return new Promise((resolve, reject) => {
      var urlAccessCode = '';
      var hashCode = '';
      var browerUserCode = '';

      this.settings.get('urlAccessCode').then((x) => {
        urlAccessCode = x;
        this.settings.get('hashCode').then((p) => {
          hashCode = p.hashCode;
          this.settings.get('browerUserCode').then((k) => {
            browerUserCode = k;
            this.settings.clearAll().then(() => {
              this.settings.set('urlAccessCode', urlAccessCode).then(() => {
                this.settings.set('hashCode', hashCode).then((p) => {
                  this.settings.set('browerUserCode', browerUserCode).then((k) => {
                    resolve({ status: 'ok' });
                  })
                })
              })
            })
          })
        })
      })
    });

  }

  /*
    checkAccessCode(urlAccessCode, confirmationCode) {
  
      return new Promise((resolve, reject) => {
        const formData = new URLSearchParams();
        formData.append('confirmationCode', confirmationCode);
        formData.append('urlAccessCode', urlAccessCode);
  
        const response = this.postData("https://russiatravel.club/pwa/access.php", formData);
  
        var result = response.then((res) => {
  
          var t = res.json();
          return t;
        }).then((t) => {
          if (t && t.status == 'ok') {
            this.accessHashCode = t.hashCode;
            this.settings.set('urlAccessCode', urlAccessCode).then(() => {
              this.settings.set('hashCode', t.hashCode).then((p) => {
                resolve({ status: 'ok' });
              });
            });
  
          }
          else {
            resolve({ status: 'error' });
          }
        }).catch(() => {
          resolve({ status: 'error' });
        });
      });
    }
  
  */

  async addComment(objectHash, objectType, comment) {

    const formData = new URLSearchParams();
    formData.append('action', 'addComment');
    formData.append('objectHash', objectHash);
    formData.append('objectType', objectType);
    formData.append('body', comment);

    return new Promise((resolve, reject) => {
      this.postData("https://russiatravel.club/pwa/pwaSaver.php", formData).then((x: any) => {
        
        var json = x.json();
        return json;
      }).then((json: any) => {
        if (json.data) {
          resolve(json.data);
        }
        else
          resolve({});
      });

    });
  }

  setLikeDislike(objectCode, objectType, likedislike) {

    const formData = new URLSearchParams();
    formData.append('action', 'likedislike');
    formData.append('objectCode', objectCode);
    formData.append('objectType', objectType);
    formData.append('likedislike', likedislike);

    return this.postData("https://russiatravel.club/pwa/pwaSaver.php", formData);

  }

  getListOfTic() {
    return new Promise((resolve, reject) => {

      this.storage.get("tics").then((s: any) => {
        if (s) {
          resolve({ tics: s.data });
        }
        else {
          const formData = new URLSearchParams();
          formData.append('dataType', 'tics');

          this.postDataOrGoToLogin("https://russiatravel.club/pwa/pwaData.php", formData).then((x: any) => {
            this.storage.set("tics", { data: x.tics });
            resolve(x);
          })
        }
      });

    });
  }

  checkinPlacesList() {
    return new Promise((resolve, reject) => {

      this.storage.get("checkinplaceslist").then((s: any) => {
        if (s && s.data) {
          resolve( s.data );
        }
        else {
          const formData = new URLSearchParams();
          formData.append('dataType', 'checkinPoints');

          this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((x: any) => {
            
            this.storage.set("checkinplaceslist", { data: x.data });
            resolve(x.data);
          })
        }
      });

    });
  }




  makeId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }


  getListOfSketches(page = 0, objects = []) {
    // debugger;
    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append('page', "" + page);
      if (objects.length > 0) {
        var objectsInString = objects.join(',');
        formData.append('objects', "" + objectsInString);
      }

      formData.append('page', "" + page);
      formData.append('dataType', "sketches");

      // debugger;
      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((x: any) => {
        console.log(x);
        // this.storage.set("spots", { data: x.spots });

        resolve(x.data);
      })
    });
  }

  getSpot(spotCode) {

    return this.storage.get("spots")
      .then((spots) => {
        if(spots.data[spotCode])
            return spots.data[spotCode];
        else {
          // var spotArray = spots.data.filter((spot) => {
          if(spots.data.isArray()){
            var spotArray = spots.data.filter((spot) => {
              return spot.spotCode == spotCode;
            });
            if (spotArray.length > 0)
              return spotArray[0];
          }
          return [];
        }
      });


  }

  getPrizeListObject() {
    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append('dataType', "prizeList");

      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((PrizeObject: any) => {
        resolve(PrizeObject);
      });
    });
  }

  getFeedHtmlObject (objects){
    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append('objects', objects);
      formData.append('dataType', "feed");

      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((feedHtmlObject: any) => {
        resolve(feedHtmlObject.data);
        console.log(feedHtmlObject);
        // debugger;
  /*      
        this.storage.get("spots").then((spotsDb:any)=>{

          if(spotsDb) {}
          else {
            spotsDb = [];
          }


          if(spotsDb.data) {}
          else {
            spotsDb.data = [];
          }

          newSpots.data.spots.forEach(element => {
            var newSpotCode = element.spotCode;
            spotsDb.data[newSpotCode] = element;
          });
          this.storage.set("spots", spotsDb);
        });
        
*/
        resolve('rgge');
      })
    });
  }
  getSpots($page = 0) {

    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append('page', "" + $page);
      formData.append('dataType', "spots");

      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((newSpots: any) => {
        console.log(newSpots);
        
        this.storage.get("spots").then((spotsDb:any)=>{

          if(spotsDb) {}
          else {
            spotsDb = [];
          }


          if(spotsDb.data) {}
          else {
            spotsDb.data = [];
          }

          newSpots.data.spots.forEach(element => {
            var newSpotCode = element.spotCode;
            spotsDb.data[newSpotCode] = element;
          });
          this.storage.set("spots", spotsDb);
        });
        

        // сложность: получаемые споты должны быть отсортированы по дате
        // логика: добавляем к тому, что есть в базе новые споты (только те, которых в базе нет)
        // если такой спот уже есть в базе, его нужно заменить
        // сортируем все споты в базе по дате (обратная сортировка)
        // this.storage.set("spots", { data: x.spots });

        resolve(newSpots.data);
      })
    });

  }

  getInfo() {
    return new Promise((resolve) => {

      this.storage.get("textInfo").then((s: any) => {
        // debugger;
        if (s) {
          resolve({ textInfo: s.data });
        }
        else {
          const formData = new URLSearchParams();
          formData.append('dataType', "textInfo");

          this.postDataOrGoToLogin("https://russiatravel.club/pwa/pwaData.php", formData).then((x: any) => {
            // debugger;
            this.storage.set("textInfo", { data: x.textInfo });
            resolve(x);
          });
        }
      });
    });
  }

  genericPostData(url, actionName, action, paramsArray) {
    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append(actionName, action);

      if (paramsArray && (Array.isArray(paramsArray))) {
        Object.keys(paramsArray).forEach(function (key, index) {
          formData.append(key, this[key]);
        }, paramsArray);
      }

      const response = this.postData(url, formData);

      var result = response.then((t) => {
        return t.json();
      }).then(x => {
        resolve(x);
      });
    });
  }

  getRegionsList() {
    return new Promise((resolve) => {
      const formData = new URLSearchParams();
      formData.append('dataType', "regionsList");

      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((RegionsObject: any) => {
        resolve(RegionsObject);
      });
    });
  }
  
  regionInfo(rtcSiteCode) {
    return new Promise((resolve, reject) => {
      const formData = new URLSearchParams();
      formData.append('dataType', 'rtcRegionFullInfo');
      formData.append('rtcSiteCode', rtcSiteCode);
      this.postDataOrGoToLogin("https://russiatravel.club/pwa/appPwaData.php", formData).then((x: any) => {
        // this.storage.set("checkinplaceslist", { data: x.data });
        resolve(x.data);
      })
    });
  }


  postInfoData(dataType, paramsArray = '') {
    return this.genericPostData(
      "https://russiatravel.club/pwa/appPwaData.php",
      "dataType",
      dataType,
      paramsArray
    );
  }
  // ACTION SAVE
  postActionData(action, paramsArray) {
    return this.genericPostData(
      "https://russiatravel.club/pwa/pwaSaver.php",
      "action",
      action,
      paramsArray
    );
  }




  // SETTINGS
  settings = {
    set: (key, value) => {
      return this.storage.get("settings").then(s => {
        if (s) {
          s[key] = value;

        }
        else {
          s = {};
          s[key] = value;
        }
        return s;
      })
        .then((s) => {
          return this.storage.set("settings", s);
        });
    },
    get: async (key) => {

      return await this.storage.get("settings").then((s) => {
        if (s && s[key])
          return s[key];
        else
          return '';
      });
    },
    clearAll: async () => {
      return this.storage.clear();
    }
  }


  getIniFeedData() {
    var x =  [
      {
        "feedItemType": "spot",
        "reviewId": "1497",
        "userNameString": null,
        "hashCode": "243e7f2d4e7c45e9cc6a1bce7b6c050c9ee14329",
        "dt": "2021-12-16 14:06:35",
        "myLike": null,
        "likeString": null,
        "title": "Выставка \"Бабки-ёжки в Архангельске\"",
        "body": "Выставка представляет один из любимых образов мастера Ольги Макаровской - Бабу Ягу. \nКаждая Баба-Яга индивидуальна и неповторима, это целостный образ со своей историей, продуманный до мелочей. Однако независимо от костюма непременным атрибутом практически всех героинь выставки является метла. ",
        "shortname": "Усадебный дом Е.К.Плотниковой",
        "location": null,
        "regionNameRu": "Архангельская область",
        "images": [
          {
            "w400": "163965279586.e07b24af522180c464791409e40bd43d.jpg",
            "fullUrl": "https:\/\/russiatravel.club\/images\/spots\/163965279586.e07b24af522180c464791409e40bd43d.jpg"
          }
        ]
      },
      {
        "feedItemType": "spot",
        "reviewId": "1522",
        "userNameString": null,
        "hashCode": "4f246634a9cc208557f025a5d4efb663285c6f10",
        "dt": "2021-12-17 18:11:10",
        "myLike": null,
        "likeString": null,
        "title": "Готовите подарки и украшаете дом? Мы готовы вам помочь!",
        "body": "Атмосферные украшения для дома и стола из натурального дерева, куклы-ангелы, ёлочные игрушки ручной работы, карманные и настенные календари и открытки изданные малым тиражом специально для посетителей музея, ароматные козули лучших мастеров города и многое другое ждет вас в музейной лавке.",
        "shortname": "Музей художника и сказочника С.Г.Писахова",
        "location": null,
        "regionNameRu": "Архангельская область",
        "images": [
          {
            "w400": "163975387034.b517db3f1a703221824d1ff9ae87315f.jpg",
            "fullUrl": "https:\/\/russiatravel.club\/images\/spots\/163975387034.b517db3f1a703221824d1ff9ae87315f.jpg"
          }
        ]
      },
    ];
  }


 regionNameByFias(fias){
   var regions = this.regionsArray();
   for (let index = 0; index < regions.length; index++) {
     const element = regions[index];
     if(element[0] == fias)
      return element[1];  
   }
   return "";
   

 }

  
  regionsArray() {
    var ret = [];
    ret.push(["8276c6a1-1a86-4f0d-8920-aba34d4cc34a","Алтайский край"]);
    ret.push(["844a80d6-5e31-4017-b422-4d9c01e9942c","Амурская область"]);
    ret.push(["294277aa-e25d-428c-95ad-46719c4ddb44","Архангельская область"]);
    ret.push(["83009239-25cb-4561-af8e-7ee111b1cb73","Астраханская область"]);
    ret.push(["639efe9d-3fc8-4438-8e70-ec4f2321f2a7","Белгородская область"]);
    ret.push(["f5807226-8be0-4ea8-91fc-39d053aec1e2","Брянская область"]);
    ret.push(["b8837188-39ee-4ff9-bc91-fcc9ed451bb3","Владимирская область"]);
    ret.push(["da051ec8-da2e-4a66-b542-473b8d221ab4","Волгоградская область"]);
    ret.push(["ed36085a-b2f5-454f-b9a9-1c9a678ee618","Вологодская область"]);
    ret.push(["b756fe6b-bbd3-44d5-9302-5bfcc740f46e","Воронежская область"]);
    ret.push(["1b507b09-48c9-434f-bf6f-65066211c73e","Еврейская автономная область"]);
    ret.push(["b6ba5716-eb48-401b-8443-b197c9578734","Забайкальский край"]);
    ret.push(["0824434f-4098-4467-af72-d4f702fed335","Ивановская область"]);
    ret.push(["6466c988-7ce3-45e5-8b97-90ae16cb1249","Иркутская область"]);
    ret.push(["1781f74e-be4a-4697-9c6b-493057c94818","Кабардино-Балкарская республика"]);
    ret.push(["90c7181e-724f-41b3-b6c6-bd3ec7ae3f30","Калининградская область"]);
    ret.push(["18133adf-90c2-438e-88c4-62c41656de70","Калужская область"]);
    ret.push(["d02f30fc-83bf-4c0f-ac2b-5729a866a207","Камчатский край"]);
    ret.push(["61b95807-388a-4cb1-9bee-889f7cf811c8","Карачаево-Черкесская республика"]);
    ret.push(["393aeccb-89ef-4a7e-ae42-08d5cebc2e30","Кемеровская область"]);
    ret.push(["0b940b96-103f-4248-850c-26b6c7296728","Кировская область"]);
    ret.push(["15784a67-8cea-425b-834a-6afe0e3ed61c","Костромская область"]);
    ret.push(["d00e1013-16bd-4c09-b3d5-3cb09fc54bd8","Краснодарский край"]);
    ret.push(["db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1","Красноярский край"]);
    ret.push(["4a3d970f-520e-46b9-b16c-50d4ca7535a8","Курганская область"]);
    ret.push(["ee594d5e-30a9-40dc-b9f2-0add1be44ba1","Курская область"]);
    ret.push(["6d1ebb35-70c6-4129-bd55-da3969658f5d","Ленинградская область"]);
    ret.push(["1490490e-49c5-421c-9572-5673ba5d80c8","Липецкая область"]);
    ret.push(["9c05e812-8679-4710-b8cb-5e8bd43cdf48","Магаданская область"]);
    ret.push(["0c5b2444-70a0-4932-980c-b4dc0d3f02b5","Москва"]);
    ret.push(["29251dcf-00a1-4e34-98d4-5c47484a36d4","Московская область"]);
    ret.push(["1c727518-c96a-4f34-9ae6-fd510da3be03","Мурманская область"]);
    ret.push(["89db3198-6803-4106-9463-cbf781eff0b8","Ненецкий автономный округ"]);
    ret.push(["88cd27e2-6a8a-4421-9718-719a28a0a088","Нижегородская область"]);
    ret.push(["e5a84b81-8ea1-49e3-b3c4-0528651be129","Новгородская область"]);
    ret.push(["1ac46b49-3209-4814-b7bf-a509ea1aecd9","Новосибирская область"]);
    ret.push(["05426864-466d-41a3-82c4-11e61cdc98ce","Омская область"]);
    ret.push(["8bcec9d6-05bc-4e53-b45c-ba0c6f3a5c44","Оренбургская область"]);
    ret.push(["5e465691-de23-4c4e-9f46-f35a125b5970","Орловская область"]);
    ret.push(["c99e7924-0428-4107-a302-4fd7c0cca3ff","Пензенская область"]);
    ret.push(["4f8b1a21-e4bb-422f-9087-d3cbf4bebc14","Пермский край"]);
    ret.push(["43909681-d6e1-432d-b61f-ddac393cb5da","Приморский край"]);
    ret.push(["f6e148a1-c9d0-4141-a608-93e3bd95e6c4","Псковская область"]);
    ret.push(["d8327a56-80de-4df2-815c-4f6ab1224c50","Республика Адыгея"]);
    ret.push(["5c48611f-5de6-4771-9695-7e36a4e7529d","Республика Алтай"]);
    ret.push(["6f2cbfd8-692a-4ee4-9b16-067210bde3fc","Республика Башкортостан"]);
    ret.push(["a84ebed3-153d-4ba9-8532-8bdf879e1f5a","Республика Бурятия"]);
    ret.push(["0bb7fa19-736d-49cf-ad0e-9774c4dae09b","Республика Дагестан"]);
    ret.push(["b2d8cd20-cabc-4deb-afad-f3c4b4d55821","Республика Ингушетия"]);
    ret.push(["491cde9d-9d76-4591-ab46-ea93c079e686","Республика Калмыкия"]);
    ret.push(["248d8071-06e1-425e-a1cf-d1ff4c4a14a8","Республика Карелия"]);
    ret.push(["c20180d9-ad9c-46d1-9eff-d60bc424592a","Республика Коми"]);
    ret.push(["bd8e6511-e4b9-4841-90de-6bbc231a789e","Республика Крым"]);
    ret.push(["de2cbfdf-9662-44a4-a4a4-8ad237ae4a3e","Республика Марий Эл"]);
    ret.push(["37a0c60a-9240-48b5-a87f-0d8c86cdb6e1","Республика Мордовия"]);
    ret.push(["c225d3db-1db6-4063-ace0-b3fe9ea3805f","Республика Саха (Якутия)"]);
    ret.push(["de459e9c-2933-4923-83d1-9c64cfd7a817","Республика Северная Осетия — Алания"]);
    ret.push(["0c089b04-099e-4e0e-955a-6bf1ce525f1a","Республика Татарстан"]);
    ret.push(["026bc56f-3731-48e9-8245-655331f596c0","Республика Тыва"]);
    ret.push(["8d3f1d35-f0f4-41b5-b5b7-e7cadf3e7bd7","Республика Хакасия"]);
    ret.push(["f10763dc-63e3-48db-83e1-9c566fe3092b","Ростовская область"]);
    ret.push(["963073ee-4dfc-48bd-9a70-d2dfc6bd1f31","Рязанская область"]);
    ret.push(["df3d7359-afa9-4aaa-8ff9-197e73906b1c","Самарская область"]);
    ret.push(["c2deb16a-0330-4f05-821f-1d09c93331e6","Санкт-Петербург"]);
    ret.push(["df594e0e-a935-4664-9d26-0bae13f904fe","Саратовская область"]);
    ret.push(["aea6280f-4648-460f-b8be-c2bc18923191","Сахалинская область"]);
    ret.push(["92b30014-4d52-4e2e-892d-928142b924bf","Свердловская область"]);
    ret.push(["6fdecb78-893a-4e3f-a5ba-aa062459463b","Севастополь"]);
    ret.push(["e8502180-6d08-431b-83ea-c7038f0df905","Смоленская область"]);
    ret.push(["327a060b-878c-4fb4-8dc4-d5595871a3d8","Ставропольский край"]);
    ret.push(["a9a71961-9363-44ba-91b5-ddf0463aebc2","Тамбовская область"]);
    ret.push(["61723327-1c20-42fe-8dfa-402638d9b396","Тверская область"]);
    ret.push(["889b1f3a-98aa-40fc-9d3d-0f41192758ab","Томская область"]);
    ret.push(["d028ec4f-f6da-4843-ada6-b68b3e0efa3d","Тульская область"]);
    ret.push(["54049357-326d-4b8f-b224-3c6dc25d6dd3","Тюменская область"]);
    ret.push(["52618b9c-bcbb-47e7-8957-95c63f0b17cc","Удмуртская республика"]);
    ret.push(["fee76045-fe22-43a4-ad58-ad99e903bd58","Ульяновская область"]);
    ret.push(["7d468b39-1afa-41ec-8c4f-97a8603cb3d4","Хабаровский край"]);
    ret.push(["d66e5325-3a25-4d29-ba86-4ca351d9704b","Ханты-Мансийский автономный округ - Югра"]);
    ret.push(["27eb7c10-a234-44da-a59c-8b1f864966de","Челябинская область"]);
    ret.push(["de67dc49-b9ba-48a3-a4cc-c2ebfeca6c5e","Чеченская республика"]);
    ret.push(["878fc621-3708-46c7-a97f-5a13a4176b3e","Чувашская республика"]);
    ret.push(["f136159b-404a-4f1f-8d8d-d169e1374d5c","Чукотский автономный округ"]);
    ret.push(["826fa834-3ee8-404f-bdbc-13a5221cfb6e","Ямало-Ненецкий автономный округ"]);
    ret.push(["a84b2ef4-db03-474b-b552-6229e801ae9b","Ярославская область"]);
    return ret;
  }
}
